import { SelfcareService } from '@yol-digital/ms-client';

export const getAccountResponse: SelfcareService.AccountResp = {
  accountId: '201907250737118628',
  status: 'active',
  ownerDetails: {
    id: '201907250737118622',
    firstName: 'E2E',
    lastName: 'Tester',
    language: 'en',
    title: 'Mr.',
    contactNumber: '',
    email: 'e2e@integration.sunrise.net',
    dob: '1991-10-07T23:00:00.000Z',
    nationality: {
      iso2: 'NL',
      iso3: 'NLD',
      countryId: '200903041337530223',
    },
    personalId: {
      id: '202305271148037237',
      status: 'valid',
      typeId: '201910230913459809',
    },
  },
  billingAddress: {
    street: 'Kreuzstrasse',
    streetNumber: '9',
    city: 'Bassersdorf',
    zip: '8303',
    co: '',
    countryIso3Code: 'CHE',
  },
  ownerAddress: {
    street: 'Kreuzstrasse',
    streetNumber: '9',
    city: 'Bassersdorf',
    zip: '8303',
    co: '',
    countryIso3Code: 'CHE',
  },
};

export const getSubscriptionsResponse: SelfcareService.SubscriptionsResp[] = [
  {
    id: 'string',
    identifier: '0799999998',
    subscriptionOnwerId: 'string',
    productCode: 'TEST_PRODUCT',
    subscriptionStatus: 'active',
  },
  {
    id: 'string1',
    identifier: '0799999999',
    subscriptionOnwerId: 'string',
    productCode: 'TEST_PRODUCT',
    subscriptionStatus: 'terminated',
  },
];

export const getSubscriptionByIdResponse: SelfcareService.SubscriptionResp = {
  ...getSubscriptionsResponse[0],
  billingType: '',
  endDate: '',
  productType: '',
  service: { id: '', status: undefined },
  startDate: '',
  subscriptionType: '',
};
